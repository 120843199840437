import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
import Headroom from 'headroom.js';
import 'slick-carousel';
import 'magnific-popup';
import $ from 'jquery';
import 'waypoints/lib/jquery.waypoints';
var faker = require('faker');
// console.log(faker)

const Mangrove = {
  init() {
    this.cache();
    this.events();
    this.initHeadroom();
    this.initSliders();
    this.initFeatureToggle();
    this.initPopups();
    this.initWaypoints();
    this.initNotifications();
    if(this.$body.hasClass('page-id-215')) {
      this.initTeamModals();
    }
    $('.hero__video video').length && $('.hero__video video')[0].play()
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
  },
  events() {
    this.$doc.on(
      'click',
      '.js-hamburger',
      event => this.showMobileNav(event)
    );
    this.$doc.on(
      'click',
      '.js-share',
      event => this.openShareWindow(event)
    );
  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    let headroom = new Headroom(theHeader, { 'offset' : 100, 'tolearnce' : 5 });
    headroom.init();
  },
  initWaypoints() {
    const $animate = $('.js-animate');
    const $fade = $('.js-fadein');
    $animate.waypoint(function(direction) {
      if (direction == 'down') {
        $(this.element).addClass('is-visible');
      }
        this.destroy();
    }, {
      offset: '65%'
    });
    $fade.waypoint(function(direction) {
      if (direction == 'down') {
        $(this.element).addClass('is-visible');
      }
      this.destroy();
    }, {
      offset: '65%'
    });
  },
  initNotifications() {
    const notification = {
      get_place: function() {
        const place = faker.address.state()
        // let city = ""
        // let state = ""

        // $.get(`http://api.zippopotam.us/us/${zip}`, function(data) {
        // const { places } = data  

        // return `${places[0]['place name']}, ${places[0]['state']}`
        // })

        return place;
      },
      set_random: function (min, max) { 
        return Math.floor(Math.random() * (max - min + 1) + min)
      },
      set_new() {   
        let interests = this.set_random(10, 50);
        $('.signed-up .interested-folk').html( interests )
        $('.interested-folk-city').html( this.get_place() )
      }
    }

    if ( $('.signed-up').length ) {

      notification.set_new()
      $('.signed-up').addClass('showing')
      
      // let counter_a = setInterval(function() {
      //   let curr =  $('.signed-up .interested-folk').html()

      //   curr = parseInt(curr);
      //   curr++

      //   $('.signed-up .interested-folk').html( curr )
      // },  notification.set_random(1100, 3000));

      const show_notif = setInterval(function() {
        $('.signed-up').removeClass('showing')
        // clearInterval( counter_a );

        setTimeout(function() {

          notification.set_new()
          $('.signed-up').addClass('showing')

          // let counter = setInterval(function() {
          //   let curr =  $('.signed-up .interested-folk').html()
    
          //   curr = parseInt(curr);
          //   curr++
    
          //   $('.signed-up .interested-folk').html( curr )
          // },  notification.set_random(1100, 3000));

          // clearInterval( counter );
        }, notification.set_random(1100, 3000));

        
      }, 7000)
    }
  },
  initTeamModals() {
    $('.js-team-pop').magnificPopup({
      type:'inline',
      midClick: true,
      mainClass: 'popup--animate popup--light',
      callbacks: {
        change: function(){
          history.pushState( {}, '', `/careers/${this.content.selector.substring(1)}` )
        },
        close: function(){
          history.pushState( {}, '', '/careers' )
        }
      }
    });
    let pathEnd = window.location.pathname.split('/').pop()
    let a = $( `a[href="#${pathEnd}"]` )
    if( a.length > 0 ){
      $('html, body').animate({
        scrollTop: $('#team-grid').offset().top-20
      }, 500);
      a.click()
    }
  },
  initPopups() {
    $('.js-inline-pop').magnificPopup({
      type:'inline',
      midClick: true,
      mainClass: 'popup--animate'
    });
    $('a[href="#video-modal"]').click(function(event) {
      event.preventDefault();
      $.magnificPopup.open({
        items: {
          src: '#video-modal',
          type: 'inline',
        },
        midClick: true,
        removalDelay: 100,
        mainClass: 'popup--animate',
        callbacks: {
          open: function() {
            $('#video-modal video').trigger('play');
          },
          close: function() {
            $('#video-modal video').trigger('pause');
          }
        }
      });
    });
  },
  initSliders() {
    $('.js-simple-slider').slick({
      dots: false,
      infinite: true,
      adaptiveHeight: true,
      prevArrow: '<button type="button" class="slick-prev"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 129 129"><g><path fill="#7F7F7F" d="m88.6,121.3c0.8,0.8 1.8,1.2 2.9,1.2s2.1-0.4 2.9-1.2c1.6-1.6 1.6-4.2 0-5.8l-51-51 51-51c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8,0l-54,53.9c-1.6,1.6-1.6,4.2 0,5.8l54,53.9z"/></g></svg></button>',
      nextArrow: '<button type="button" class="slick-next"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 129 129"><g><path fill="#7F7F7F" d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z"/></g></svg></button>',
      responsive: [
        {
          breakpoint: 960,
          settings: {
            arrows: false,
            dots: true
          }
        },
      ]
    });
    $('.js-gallery-slider').slick({
      dots: true,
      arrows: false
    });
    $('.js-infinite-slider--small').slick({
      infinite: true,
      slidesToShow: 4,
      dots: false,
      arrows: false,
      autoplay: true,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1
          }
        },
      ]
    });
    $('.js-infinite-slider--medium').slick({
      infinite: true,
      slidesToShow: 3,
      dots: false,
      arrows: false,
      autoplay: true,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1
          }
        },
      ]
    });
    $('.js-featured-carousel').slick({
      dots: false,
      arrows: false,
      centerMode: true,
      centerPadding: '0',
      infinte: true
    });
    $('.js-featured-carousel').on('click', '.slick-slide', function (e) {
      e.stopPropagation();
      var index = $(this).data("slick-index");
      $('.slick-slider').slick('slickGoTo', index);
    });
  },
  initFeatureToggle() {
    if ($(window).width() > 960) {
      $('.js-content-tabs .toggled-content__tab:first').addClass('active');
      $('.js-content-tabs .toggled-content__nav li:first').addClass('active');
      $('.js-content-tabs .toggled-content__nav li').click(function(event) {
        event.preventDefault();
        $('.js-content-tabs .toggled-content__nav li').removeClass('active');
        $(this).addClass('active');
        $('.js-content-tabs .toggled-content__tab').removeClass('active');
        var selectTab = $(this).find('a').attr("href");
        $(selectTab).addClass('active');
      });
    }
  },
  showMobileNav(event) {
    event.preventDefault();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    if ( this.$mobileNav.hasClass('is-active') ) {
      this.$hamburger.attr("aria-pressed", "true");
    } else {
      this.$hamburger.removeAttr("aria-pressed");
    }
  },
};

Mangrove.init();
